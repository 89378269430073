import React, { useState } from "react";

const PhoneNumberForm = ({ onSubmit }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(name, phoneNumber, email);
  };

  return (
    <div className="FormContent">
      <div className="content">
        <form onSubmit={handleSubmit}>
            
         <h1 className="title">POUR PARTICIPER, ENREGISTREZ <br/>VOS INFOS !</h1>
          <div className="form-group">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="NOM + PRÉNOMS"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              maxLength="10"
              placeholder="TÉLÉPHONE"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="ADRESSE EMAIL"
            />
          </div>
          <button className="start-button btn-secondary" type="submit">VALIDER ET COMMENCER ! </button>
        </form>
      </div>
    </div>
  );
};

export default PhoneNumberForm;
