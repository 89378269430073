import React from "react";

const Question = ({ question, onAnswer }) => {
  if (!question) {
    return <div>Loading question...</div>;
  }

  return (
    <div className="">
      <h2 className="quizz">{question.question}</h2>
      <div className="answer">
        {question.answers.map((answer, index) => (
          <button
            className="start-button btn-secondary"
            key={index}
            onClick={() => onAnswer(answer)}
          >
            {answer}
          </button>
        ))}
        <img
          src={`${process.env.PUBLIC_URL}/titre-c.png`}
          alt="Jumia Logo"
          className="logote"
        />
      </div>
    </div>
  );
};

export default Question;
