import React from 'react';

const Modal = ({ content }) => {
  return (
    <div className="modal">
      <div id={content} className="modal-content">
        <button className="close" onClick={() => window.location.reload()}>
          &times;
        </button>
        <p></p>
      </div>
    </div>
  );
};

export default Modal;
