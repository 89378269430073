import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhoneNumberForm from './PhoneNumberForm';
import Question from './Question';
import Loader from './Loader';
import Modal from './Modal';
import './style.css';

const APIURL = 'https://quizz-onair.com/quizz-onair/public';
const questions = [
  { question: 'Quand Jumia a-t-il été créé ?', answers: ['2010', '2012'], correct: '2012' },
  { question: 'Quelle est la principale mission de Jumia ?', answers: ['Connecter les vendeurs et les acheteurs sur une plateforme en ligne', 'Proposer des services de location de maison'], correct: 'Connecter les vendeurs et les acheteurs sur une plateforme en ligne' },
  { question: 'Jumia existe dans combien de pays ?', answers: ['2010', '2012'], correct: '2012' },
  { question: 'Dans quel continent Jumia n’est pas ?', answers: ['En Europe', 'En Afrique'], correct: 'En Europe' },
  { question: 'Dans quel pays Jumia n’est pas présent?', answers: ['Maroc', 'Benin'], correct: 'Benin' },
  { question: 'Les ventes flash commencent à quelle heure?', answers: ['11h', '19h'], correct: '11h' },
  { question: 'Quel type de paiement est proposé par Jumia ?', answers: ['Paiement en ligne et à la livraison', 'Paiement en ligne uniquement'], correct: 'Paiement en ligne et à la livraison' },
  { question: 'Quelle est l’option de livraison la plus rapide offerte par Jumia ?', answers: ['Jumia express', 'Livraison en deux jours'], correct: 'Jumia express' },
  { question: 'Quel type de produit Jumia ne vend pas ?', answers: ['Vêtements', 'Véhicules'], correct: 'Véhicules' },
  { question: 'Combien de jours dispose le client pour ramener un produit ?', answers: ['10 jours', '7 jours'], correct: '10 jours' },
  { question: 'Quel service offre Jumia Pay ?', answers: ['Offrir des prêts personnels', 'Faciliter les paiements en ligne'], correct: 'Faciliter les paiements en ligne' },
  { question: 'Dans quel mois célèbre-t-on Jumia Black Friday ?', answers: ['Mars', 'Novembre'], correct: 'Novembre' },
  { question: 'Quel événement Jumia organise en Juin ?', answers: ['Jumia Anniversaire', 'Jumia Festival'], correct: 'Jumia Anniversaire' },
  { question: 'Quel produit vous ne trouverez pas sur Jumia ?', answers: ['Brique de construction', 'Laisse pour chien'], correct: 'Laisse pour chien' },
  { question: 'Quelle marque vous ne trouverez pas sur Jumia ?', answers: ['Adidas', 'Nike'], correct: 'Adidas' },
  { question: 'Sur Jumia est-il possible de payer par Wave à la commande ?', answers: ['Oui', 'Non'], correct: 'Oui' },
  { question: 'Quel est le nom de l’application mobile de Jumia ?', answers: ['Jumia App', 'Jumia Mobile'], correct: 'Jumia App' },
];

const prizes = ['Prize 1', 'Prize 2', 'Prize 3'];

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [chances, setChances] = useState(2);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [gameStatus, setGameStatus] = useState('');
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    // Récupérer et configurer le token CSRF
    axios.get(`${APIURL}/csrf-token`).then(response => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrfToken;
    });
  }, []);

  const validatePhoneNumber = (number) => {
    const regex = /^(01|05|07)\d{8}$/;
    return regex.test(number);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const startGame = (name, number, email) => {
    if (validatePhoneNumber(number) && validateEmail(email)) {
      checkPhoneNumberAndEmail(number, email);
    } else {
      alert('Numéro de téléphone ou email invalide');
    }
  };

  const checkPhoneNumberAndEmail = (number, email) => {
    setShowLoader(true);
    axios.post(`${APIURL}/checkPhoneNumberAndEmail`, { phoneNumber: number, email })
      .then(response => {
        setShowLoader(false);
        if (response.data.exists) {
          alert('Numéro de téléphone ou email déjà utilisé');
        } else {
          setPhoneNumber(number);
          setEmail(email);
          setIsValidPhoneNumber(true);
          setCurrentQuestion(questions[Math.floor(Math.random() * questions.length)]);
          setCurrentPage('quiz');
        }
      })
      .catch(error => {
        setShowLoader(false);
        console.error('There was an error checking the phone number or email!', error);
      });
  };

  const handleAnswer = (answer) => {
    if (answer === currentQuestion.correct) {
      const prize = prizes[Math.floor(Math.random() * prizes.length)];
      setModalContent('win');
      setGameStatus('Gagné');
      setShowModal(true);
      savePlayerStatus('Gagné');
    } else {
      if (chances > 1) {
        setChances(chances - 1);
        setCurrentQuestion(questions[Math.floor(Math.random() * questions.length)]);
      } else {
        setModalContent('lost');
        setGameStatus('lost');
        setShowModal(true);
        savePlayerStatus('Perdu');
      }
    }
  };

  const savePlayerStatus = (status) => {
    axios.post(`${APIURL}/savePlayer`, { phoneNumber, email, status })
      .then(response => {
        console.log(response.data);
        setPlayers([...players, { phoneNumber, email, status }]);
      })
      .catch(error => {
        console.error('There was an error saving the player!', error);
      });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const closePage = () => {
    window.location.reload();
  };

  const goToFormPage = () => {
    setCurrentPage('form');
  };

  return (
    <div className="App">
      {currentPage === 'home' && (
        <div className="container">
          <div className="header">
            <img src={`${process.env.PUBLIC_URL}/jumiaLogo.png`} alt="Jumia Logo" className="logo" />
          </div>
          <div className="content">
            <img src={`${process.env.PUBLIC_URL}/img-team-p.jpg`} alt="Team Paiya x Ste Milano" className="team-image" />
            <div className="text-content">
              <h1>Participe à un quizz pour tenter de gagner de nombreux lots!</h1>
              <button onClick={goToFormPage} className="start-button">On commence !</button>
            </div>
          </div>
        </div>
      )}
      {currentPage === 'form' && (
        <PhoneNumberForm onSubmit={startGame} />
      )}
      {currentPage === 'quiz' && (
        <div className='question'>
          <div className='mo'>
            <div className="header">
              <img src={`${process.env.PUBLIC_URL}/jumiaLogo.png`} alt="Jumia Logo" className="logo" />
            </div>
            <h3>Vous avez {chances} chances restantes</h3>
            {showLoader ? <Loader /> : <Question question={currentQuestion} onAnswer={handleAnswer} />}
          </div>
        </div>
      )}
      {showModal && <Modal content={modalContent} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default App;
